@import '../sassStyles/variables';

.container {
  //height: calc(100vh - #{$header-height});
  //background: $bg;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  padding: 6rem;
  //flex-wrap: wrap;
  @include breakpoint(sd) {
    padding: 6rem 1rem 1rem;
  }
}

//h1 {
//  padding: 1.5rem 2.5rem;
//  background: linear-gradient(220deg, $primary, rgba($primary, .5));
//  border-radius: $border-radius calc(#{$border-radius}*10);
//}