@import "../sassStyles/variables";
@import "../sassStyles/components/button";

.header {
  background: $dark;
  width: 100%;
  height: $header-height;
  padding: 0 1.5rem;
  @include breakpoint(md) {
    padding: 0 3rem;
  }
  transition: .3s ease all;
  &__content {
    overflow: hidden;
    color: $light;
    margin: 0 auto;
    max-width: 192rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    &__logo {
      font-size: 2rem;
      font-weight: bold;
      text-decoration: none;
      color: $light;
    }
    &__nav {
      top: 0;
      left: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba($dark, .9);
      backdrop-filter: blur(.2rem);
      transform: translate(0);
      transition: .3s ease-out transform;
      @include breakpoint(md) {
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-lg;
        @include  breakpoint(md) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          margin-right: calc(.5rem + #{$spacing-md});
        }
        li {
          font-size: 1.4rem;
          &:not(:last-child) {
            margin-bottom: $spacing-lg;
            @include breakpoint(md) {
              margin-bottom: 0;
              margin-right: $spacing-md;
            }
          }
          a {
            margin: 1rem;
            display: block;
            text-decoration: none;
            color: inherit;
            padding: .75rem 1.25rem;
            border-radius: $border-radius;
            transition: .3s all ease;
            &:hover {
              background: rgba($light, .1);
            }
            &:active {
              border-radius: calc(.6rem + #{$border-radius});
              background: linear-gradient(rgba($light, .1), rgba($light, .2));
            }
          }
        }
      }
      &.isMenu {
          transform: translate(-100%);
      }
    }
    &__toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 2rem;
      transition: .3s all ease;
      position: relative;
      &:hover {
        color: $grey;
      }
      @include breakpoint(md) {
        display: none;
      }
    }
  }
}

.button {
  width: auto;
  padding: 0.75rem 3rem;
  white-space: nowrap;
  &--link {
    margin-right: .5rem;
    text-decoration: none;
  }
}

.margin-mobile-buttons {
  margin-bottom: 2rem;
}