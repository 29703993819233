@import "../sassStyles/typography";
@import "../sassStyles/variables";

.title {
  text-align: center;
  grid-row: 1/4;
  font-weight: 400;
  font-size: 2rem;
  color: $dark;
}

.fav {
  display: block;
  padding-top: 1rem;
  margin-left: auto;
  margin-right: 1rem;
  justify-content: right;
  grid-row: 4/4;
  font-size: 2.2rem;
  text-align: right;
}