@import "./variables";

*,
*::after,
*::before {
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  font-family: $Poppins;
  box-sizing: border-box;
  color: $dark;
  //background: $bg;
  background: #bdc3c7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, rgba(96, 106, 115, 0.2), rgba(189, 195, 199, 0.25));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, rgba(96, 106, 115, 0.2), rgba(189, 195, 199, 0.25)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

#root {
  height: 100%;
}