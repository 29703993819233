@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Shadows+Into+Light&display=swap');

$Poppins: 'Poppins', sans-serif;

// colors

$dark: rgb(29, 29, 29);
$grey: #ababab;
$light: #fff;
$primary: rgb(0, 0, 255);
$primary-light: rgb(200, 200, 255);
//$bg: rgb(244, 244, 255);
$bg: rgba(224, 224, 224, 0.5);

// spacing
$spacing-md: 1.6rem;
$spacing-lg: 3.2rem;

// border radius
$border-radius: 1.2rem;

// header height
$header-height: 10rem;

@mixin breakpoint($point) {
  @if $point == md {
    // 768px
    @media (min-width: 48rem) {
      @content;
    }
  }
  @if $point == sd {
    // 468px
    @media (max-width: 40rem) {
      @content;
    }
  }
}