@import "../variables";

.form {
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }
  &__input {
    margin: auto;
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    border-radius: .2rem;
    background-color: rgba($light, .5);
    border: none;
    border-bottom: .3rem solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;

    &::-webkit-input-placeholder {
      color: #ababab;
    }

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($dark, .1);
      border-bottom: .3rem solid $primary;
      &:invalid {
        border-bottom: .3rem solid darkred;
      }
    }
  }

  &__label {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 2rem;
    margin-top: .7rem;
    display: block;
    transition: all .3s;
    text-align: left;
    transform: translateX(1.8rem);
  }
  &__error {
    padding-top: 1rem;
    font-size: 1.3rem;
    color: orangered;
    font-weight: 600;
  }
  &__success {
    font-size: 1.3rem;
    color: green;
    font-weight: 500;
  }
  &__input:placeholder-shown + &__label {
    transform: translateY(-4rem) translateX(1.8rem);
    opacity: 0;
    visibility: hidden;
  }
}