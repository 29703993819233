.u-margin-bottom-large {
  margin-bottom: 6rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-medium-small {
  margin-bottom: 3.4rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 2rem !important;
}

.u-margin-top-large {
  margin-top: 6rem !important;
}

.u-margin-top-medium {
  margin-top: 2.5rem !important;
}

.u-margin-top-medium-2 {
  margin-top: 5rem !important;
}

.u-margin-top-small {
  margin-top: 2rem !important;
}