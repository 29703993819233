@import "../sassStyles/variables";
@import "../sassStyles/typography";
@import "../sassStyles/utilities";
@import "../sassStyles/components/button";
@import "../sassStyles/components/form";

.text {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: .7rem;
}