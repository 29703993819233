@import "../sassStyles/variables";

.card {
  //margin-left: auto;
  //margin-right: auto;
  margin: 1.5rem;
  padding: 20px;
  width: 500px;
  //min-height: 100px;
  display: grid;
  grid-template-rows: 10px 10px 1fr auto;
  //grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  background: #ffffff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, rgb(255, 255, 255), rgb(248, 248, 248));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, rgb(255, 255, 255), rgb(248, 248, 248)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }
  @include breakpoint(sd) {
    max-width: 35rem;
  }
}