@import "../variables";

.button {
  cursor: pointer;
  width: 90%;
  outline: none;
  padding: .75rem 1.25rem;
  border-radius: $border-radius;
  font-size: 1.4rem;
  font-family: inherit;
  background: $primary;
  color: $light;
  border: .1rem solid transparent;
  transition: .3s ease all;

  &:hover {
    border-color: rgba($primary, .4);
    background: rgba($primary, .6);
    color: $light;
  }
  &:active {
    border-color: $primary;
    background: linear-gradient(rgba($primary, .2), rgba($primary, .3));
    color: $light;
  }
  &[disabled=disabled], &:disabled {
    background: $primary-light;
    color: $dark;
  }
}