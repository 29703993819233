@import "../sassStyles/typography";
@import "../sassStyles/utilities";
@import "../sassStyles/components/button";

.button {
  //display: inline;
  text-align: center;
  //position: relative;
  //margin-left: auto;
  //margin-right: auto;
  width: auto;
  padding: 1rem 3rem;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 30px;
}