.heading-secondary {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.heading-primary {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 800;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.link {
  display: block;
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
}